import React from 'react'
import { Helmet } from 'react-helmet'
import MessageSentTpl from 'templates/contacts/message-sent'

const BriefSentPage = () => (
	<>
		<MessageSentTpl />
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>
	</>
)

export default BriefSentPage
