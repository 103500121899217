import React from 'react'
import { Root } from 'templates/layout'
import { DesignRow, IntenseText, RegularText } from 'components/design'
import { graphql, useStaticQuery } from 'gatsby'
import SocialLinks from 'embeds/social-links'
import { EmailLink, PhoneLink } from 'components/design'

export default function MessageSentTpl() {
	const {
		main_email,
		main_subheading,
		phone_formatted,
		country_specific_phones,
		social_links,
	} = useContactPageData()

	return (
		<Root>
			<DesignRow
				className="mt-48"
				leftColumn={<h1>Thank you for filling in the form!</h1>}
				rightColumn={
					<>
						<IntenseText className="lg:mt-32">
							Our Client Manager will contact you shortly.
						</IntenseText>
						<RegularText className="mt-8">
							Please feel free to contact us 24/7 via email or phone below.
						</RegularText>
					</>
				}
			/>

			<DesignRow
				className="mt-20 mb-20"
				leftColumn={
					<>
						<h5>Give us a call:</h5>
						<IntenseText className="mt-1">
							<PhoneLink
								number={phone_formatted}
								countrySpecific={country_specific_phones}
							/>
						</IntenseText>
					</>
				}
				rightColumn={
					<>
						<h5>{main_subheading}:</h5>
						<IntenseText className="mt-1">
							<EmailLink email={main_email} />
						</IntenseText>
					</>
				}
			/>

			<DesignRow
				className="mt-10"
				leftColumn={
					<>
						<h5>Check out our social profiles:</h5>
					</>
				}
				rightColumn={
					<div className="w-screen-step-6 lg:w-screen-step-4">
						<SocialLinks links={social_links} />
					</div>
				}
			/>
		</Root>
	)
}

function useContactPageData() {
	const response = useStaticQuery(graphql`
		query MessageSentQuery {
			prismicContactsPage {
				...ContactsFragment
			}
		}
	`)

	return response.prismicContactsPage.data
}
